.hero-xl--1d857 {
  letter-spacing: var(--tkww-union-typography-category-hero-xl-letter-spacing, -0.02em);
  font-weight: var(--tkww-union-typography-category-hero-xl-font-weight, var(--tkww-union-typography-extra-bold-font-weight, 800));
  font-family: var(--tkww-union-typography-category-hero-xl-font-family, var(--tkww-union-typography-primary-font-family, union-primary-font, union-sans-serif, -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif));
  font-size: var(--tkww-union-typography-category-hero-xl-font-size, 5.625rem);
  line-height: var(--tkww-union-typography-category-hero-xl-line-height, 1);
}

.hero-lg--f935c {
  letter-spacing: var(--tkww-union-typography-category-hero-lg-letter-spacing, -0.02em);
  font-weight: var(--tkww-union-typography-category-hero-lg-font-weight, var(--tkww-union-typography-extra-bold-font-weight, 800));
  font-family: var(--tkww-union-typography-category-hero-lg-font-family, var(--tkww-union-typography-primary-font-family, union-primary-font, union-sans-serif, -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif));
  font-size: var(--tkww-union-typography-category-hero-lg-font-size, 3.75rem);
  line-height: var(--tkww-union-typography-category-hero-lg-line-height, 1);
}

.hero-md--c3f1a {
  letter-spacing: var(--tkww-union-typography-category-hero-md-letter-spacing, -0.02em);
  font-weight: var(--tkww-union-typography-category-hero-md-font-weight, var(--tkww-union-typography-extra-bold-font-weight, 800));
  font-family: var(--tkww-union-typography-category-hero-md-font-family, var(--tkww-union-typography-primary-font-family, union-primary-font, union-sans-serif, -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif));
  font-size: var(--tkww-union-typography-category-hero-md-font-size, 3rem);
  line-height: var(--tkww-union-typography-category-hero-md-line-height, 1);
}

.hero-sm--d1e56 {
  letter-spacing: var(--tkww-union-typography-category-hero-sm-letter-spacing, -0.02em);
  font-weight: var(--tkww-union-typography-category-hero-sm-font-weight, var(--tkww-union-typography-extra-bold-font-weight, 800));
  font-family: var(--tkww-union-typography-category-hero-sm-font-family, var(--tkww-union-typography-primary-font-family, union-primary-font, union-sans-serif, -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif));
  font-size: var(--tkww-union-typography-category-hero-sm-font-size, 2.125rem);
  line-height: var(--tkww-union-typography-category-hero-sm-line-height, 1);
}

.h1--62ab0 {
  font-weight: var(--tkww-union-typography-category-h1-font-weight, var(--tkww-union-typography-bold-font-weight, 500));
  font-family: var(--tkww-union-typography-category-h1-font-family, var(--tkww-union-typography-primary-font-family, union-primary-font, union-sans-serif, -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif));
  font-size: var(--tkww-union-typography-category-h1-font-size, 1.875rem);
  line-height: var(--tkww-union-typography-category-h1-line-height, 1.2);
}

.h2--b75a9 {
  font-weight: var(--tkww-union-typography-category-h2-font-weight, var(--tkww-union-typography-bold-font-weight, 500));
  font-family: var(--tkww-union-typography-category-h2-font-family, var(--tkww-union-typography-primary-font-family, union-primary-font, union-sans-serif, -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif));
  font-size: var(--tkww-union-typography-category-h2-font-size, 1.75rem);
  line-height: var(--tkww-union-typography-category-h2-line-height, 1.2142857143);
}

.h3--ddfd5 {
  letter-spacing: var(--tkww-union-typography-category-h3-letter-spacing, 0);
  font-weight: var(--tkww-union-typography-category-h3-font-weight, var(--tkww-union-typography-bold-font-weight, 500));
  font-family: var(--tkww-union-typography-category-h3-font-family, var(--tkww-union-typography-primary-font-family, union-primary-font, union-sans-serif, -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif));
  font-size: var(--tkww-union-typography-category-h3-font-size, var(--tkww-union-typography-scale-600-font-size, 1.5rem));
  line-height: var(--tkww-union-typography-category-h3-line-height, 1.1666666667);
}

.h4--224bc {
  letter-spacing: var(--tkww-union-typography-category-h4-letter-spacing, 0);
  font-weight: var(--tkww-union-typography-category-h4-font-weight, var(--tkww-union-typography-bold-font-weight, 500));
  font-family: var(--tkww-union-typography-category-h4-font-family, var(--tkww-union-typography-primary-font-family, union-primary-font, union-sans-serif, -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif));
  font-size: var(--tkww-union-typography-category-h4-font-size, 1.375rem);
  line-height: var(--tkww-union-typography-category-h4-line-height, 1.1818181818);
}

.subhead--150a2 {
  letter-spacing: var(--tkww-union-typography-category-subhead-letter-spacing, 0);
  font-family: var(--tkww-union-typography-category-subhead-font-family, var(--tkww-union-typography-primary-font-family, union-primary-font, union-sans-serif, -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif));
  font-size: var(--tkww-union-typography-category-subhead-font-size, var(--tkww-union-typography-scale-500-font-size, 1.25rem));
  line-height: var(--tkww-union-typography-category-subhead-line-height, var(--tkww-union-typography-scale-500-line-height, 1.4));
}

.editorial--802b2 {
  letter-spacing: var(--tkww-union-typography-category-editorial-letter-spacing, 0);
  font-family: var(--tkww-union-typography-category-editorial-font-family, var(--tkww-union-typography-primary-font-family, union-primary-font, union-sans-serif, -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif));
  font-size: var(--tkww-union-typography-category-editorial-font-size, var(--tkww-union-typography-scale-450-font-size, 1.125rem));
  line-height: var(--tkww-union-typography-category-editorial-line-height, 1.4444444444);
}

.body1--e44d4 {
  letter-spacing: var(--tkww-union-typography-category-body1-letter-spacing, 0);
  font-family: var(--tkww-union-typography-category-body1-font-family, var(--tkww-union-typography-primary-font-family, union-primary-font, union-sans-serif, -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif));
  font-size: var(--tkww-union-typography-category-body1-font-size, var(--tkww-union-typography-scale-300-font-size, 1rem));
  line-height: var(--tkww-union-typography-category-body1-line-height, 1.375);
}

.body2--f71c1 {
  letter-spacing: var(--tkww-union-typography-category-body2-letter-spacing, 0);
  font-family: var(--tkww-union-typography-category-body2-font-family, var(--tkww-union-typography-primary-font-family, union-primary-font, union-sans-serif, -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif));
  font-size: var(--tkww-union-typography-category-body2-font-size, var(--tkww-union-typography-scale-200-font-size, 0.875rem));
  line-height: var(--tkww-union-typography-category-body2-line-height, var(--tkww-union-typography-scale-200-line-height, 1.42857));
}

.buttonL--736ee {
  letter-spacing: var(--tkww-union-typography-category-buttonL-letter-spacing, 0);
  font-weight: var(--tkww-union-typography-category-buttonL-font-weight, var(--tkww-union-typography-bold-font-weight, 500));
  font-family: var(--tkww-union-typography-category-buttonL-font-family, var(--tkww-union-typography-primary-font-family, union-primary-font, union-sans-serif, -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif));
  font-size: var(--tkww-union-typography-category-buttonL-font-size, var(--tkww-union-typography-scale-300-font-size, 1rem));
  line-height: var(--tkww-union-typography-category-buttonL-line-height, var(--tkww-union-typography-scale-300-line-height, 1.5));
}

.buttonM--9f7ab {
  letter-spacing: var(--tkww-union-typography-category-buttonM-letter-spacing, 0);
  font-weight: var(--tkww-union-typography-category-buttonM-font-weight, var(--tkww-union-typography-bold-font-weight, 500));
  font-family: var(--tkww-union-typography-category-buttonM-font-family, var(--tkww-union-typography-primary-font-family, union-primary-font, union-sans-serif, -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif));
  font-size: var(--tkww-union-typography-category-buttonM-font-size, var(--tkww-union-typography-scale-200-font-size, 0.875rem));
  line-height: var(--tkww-union-typography-category-buttonM-line-height, var(--tkww-union-typography-scale-200-line-height, 1.42857));
}

.overline--fd5bf {
  letter-spacing: var(--tkww-union-typography-category-overline-letter-spacing, 2px);
  text-transform: var(--tkww-union-typography-category-overline-text-transform, uppercase);
  font-weight: var(--tkww-union-typography-category-overline-font-weight, var(--tkww-union-typography-bold-font-weight, 500));
  font-family: var(--tkww-union-typography-category-overline-font-family, var(--tkww-union-typography-primary-font-family, union-primary-font, union-sans-serif, -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif));
  font-size: var(--tkww-union-typography-category-overline-font-size, var(--tkww-union-typography-scale-100-font-size, 0.75rem));
  line-height: var(--tkww-union-typography-category-overline-line-height, 1.3333333333);
}

.caption-lg--e66c1 {
  letter-spacing: var(--tkww-union-typography-category-caption-lg-letter-spacing, 0);
  font-weight: var(--tkww-union-typography-category-caption-lg-font-weight, var(--tkww-union-typography-regular-font-weight, 400));
  font-family: var(--tkww-union-typography-category-caption-lg-font-family, var(--tkww-union-typography-primary-font-family, union-primary-font, union-sans-serif, -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif));
  font-size: var(--tkww-union-typography-category-caption-lg-font-size, var(--tkww-union-typography-scale-100-font-size, 0.75rem));
  line-height: var(--tkww-union-typography-category-caption-lg-line-height, 1.3333333333);
}

.caption-sm--e338f {
  letter-spacing: var(--tkww-union-typography-category-caption-sm-letter-spacing, 0);
  font-weight: var(--tkww-union-typography-category-caption-sm-font-weight, var(--tkww-union-typography-regular-font-weight, 400));
  font-family: var(--tkww-union-typography-category-caption-sm-font-family, var(--tkww-union-typography-primary-font-family, union-primary-font, union-sans-serif, -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif));
  font-size: var(--tkww-union-typography-category-caption-sm-font-size, 0.6875rem);
  line-height: var(--tkww-union-typography-category-caption-sm-line-height, 1.4545454545);
}

@media (max-width: 767.98px) {
  .hero-xl--1d857 {
    font-size: var(--tkww-union-typography-category-hero-xl-font-size, 3.75rem);
    line-height: var(--tkww-union-typography-category-hero-xl-line-height, 1);
  }
}
@media (max-width: 767.98px) {
  .hero-lg--f935c {
    font-size: var(--tkww-union-typography-category-hero-lg-font-size, 2.8125rem);
    line-height: var(--tkww-union-typography-category-hero-lg-line-height, 1);
  }
}
@media (max-width: 767.98px) {
  .hero-md--c3f1a {
    font-size: var(--tkww-union-typography-category-hero-md-font-size, 2.25rem);
    line-height: var(--tkww-union-typography-category-hero-md-line-height, 1);
  }
}
@media (max-width: 767.98px) {
  .hero-sm--d1e56 {
    font-size: var(--tkww-union-typography-category-hero-sm-font-size, 1.875rem);
    line-height: var(--tkww-union-typography-category-hero-sm-line-height, 1);
  }
}
@media (max-width: 767.98px) {
  .h1--62ab0 {
    font-size: var(--tkww-union-typography-category-h1-font-size, 1.75rem);
    line-height: var(--tkww-union-typography-category-h1-line-height, 1.2142857143);
  }
}
@media (max-width: 767.98px) {
  .h2--b75a9 {
    font-size: var(--tkww-union-typography-category-h2-font-size, 1.625rem);
    line-height: var(--tkww-union-typography-category-h2-line-height, 1.2307692308);
  }
}
@media (max-width: 767.98px) {
  .h3--ddfd5 {
    font-size: var(--tkww-union-typography-category-h3-font-size, 1.5rem);
    line-height: var(--tkww-union-typography-category-h3-line-height, 1.1666666667);
  }
}
@media (max-width: 767.98px) {
  .h4--224bc {
    font-size: var(--tkww-union-typography-category-h4-font-size, 1.375rem);
    line-height: var(--tkww-union-typography-category-h4-line-height, 1.1818181818);
  }
}
@media (max-width: 767.98px) {
  .subhead--150a2 {
    font-size: var(--tkww-union-typography-category-subhead-font-size, 1.25rem);
    line-height: var(--tkww-union-typography-category-subhead-line-height, 1.4);
  }
}
/* stylelint-disable font-family-no-missing-generic-family-keyword */
.primary--8529c {
  font-family: var(--tkww-union-typography-primary-font-family, union-primary-font, union-sans-serif, -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif);
  font-weight: var(--tkww-union-typography-regular-font-weight, 400);
}

.secondary--1b882 {
  font-family: var(--tkww-union-typography-secondary-font-family, union-secondary-font, union-serif, Georgia, "Times New Roman", Times, serif);
  font-weight: var(--tkww-union-typography-regular-font-weight, 400);
}

.primaryBold--1abd6 {
  font-family: var(--tkww-union-typography-primary-font-family, union-primary-font, union-sans-serif, -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif);
  font-weight: var(--tkww-union-typography-bold-font-weight, 500);
}

.primaryExtraBold--b783f {
  font-family: var(--tkww-union-typography-primary-font-family, union-primary-font, union-sans-serif, -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif);
  font-weight: var(--tkww-union-typography-extra-bold-font-weight, 800);
}
.icon--1935d {
  display: inline-block;
  fill: currentColor;
  stroke: currentColor;
  stroke-width: 0;
}

.icon-has-no-size--189a8 {
  height: 1em;
  width: 1em;
}

.size-sm--ad48b {
  height: var(--tkww-union-icons-sm-size, 1rem);
  width: var(--tkww-union-icons-sm-size, 1rem);
}

.size-md--ffa00 {
  height: var(--tkww-union-icons-md-size, 1.5rem);
  width: var(--tkww-union-icons-md-size, 1.5rem);
}

.size-lg--2b900 {
  height: var(--tkww-union-icons-lg-size, 2rem);
  width: var(--tkww-union-icons-lg-size, 2rem);
}
.sr-only--19d91 {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}
.icon--1935d {
  display: inline-block;
  fill: currentColor;
  stroke: currentColor;
  stroke-width: 0;
}

.icon-has-no-size--189a8 {
  height: 1em;
  width: 1em;
}

.size-sm--ad48b {
  height: var(--tkww-union-icons-sm-size, 1rem);
  width: var(--tkww-union-icons-sm-size, 1rem);
}

.size-md--ffa00 {
  height: var(--tkww-union-icons-md-size, 1.5rem);
  width: var(--tkww-union-icons-md-size, 1.5rem);
}

.size-lg--2b900 {
  height: var(--tkww-union-icons-lg-size, 2rem);
  width: var(--tkww-union-icons-lg-size, 2rem);
}
.modal-container--a1f1e {
  --union-modal-z-index: calc(var(--union-modal-level, 1) * 20);
}
.container--e7bf7 {
  align-items: center;
  cursor: pointer;
  justify-content: center;
}

.container--e7bf7 * {
  pointer-events: none;
}

.is-inline--fda67 {
  display: inline-flex;
}

.is-block--c90e8 {
  display: flex;
}

.size-sm--9552e {
  height: var(--tkww-union-icons-interactive-sm-size, 2rem);
  width: var(--tkww-union-icons-interactive-sm-size, 2rem);
}

.size-md--a869b {
  height: var(--tkww-union-icons-interactive-md-size, 2.75rem);
  width: var(--tkww-union-icons-interactive-md-size, 2.75rem);
}

.size-lg--47a16 {
  height: var(--tkww-union-icons-interactive-lg-size, 3rem);
  width: var(--tkww-union-icons-interactive-lg-size, 3rem);
}
.container--eb861 {
  border-radius: 0.25rem;
  color: var(--tkww-union-alerts-color, var(--tkww-union-color-text-default, var(--tkww-union-color-neutral-black, #000)));
  display: flex;
  padding: 16px;
  text-align: left;
  transition: all 0.25s ease;
  transition-property: height, padding-top, padding-bottom, opacity;
}

.children--c06a3 a {
  color: var(--tkww-union-alerts-link-color, var(--tkww-union-color-link-dark-on-light, var(--tkww-union-color-neutral-black, #000)));
  text-decoration: underline;
}

.alert-icon--c2d73 {
  margin-right: 12px;
  min-width: 1.5rem;
}

.icon-error--1926b {
  color: var(--tkww-union-alerts-error-icon-color, var(--tkww-union-color-status-error-200, #EB1400));
}

.icon-info--86642 {
  color: var(--tkww-union-alerts-info-icon-color, var(--tkww-union-color-status-info-300, #114394));
}

.icon-success--6c275 {
  color: var(--tkww-union-alerts-success-icon-color, var(--tkww-union-color-status-success-200, #008A05));
}

.icon-warning--d5bb4 {
  color: var(--tkww-union-alerts-warning-icon-color, var(--tkww-union-color-status-warning-200, #C25400));
}

.error--357a5 {
  background-color: var(--tkww-union-alerts-error-background-color, var(--tkww-union-color-background-error, var(--tkww-union-color-status-error-100, #FFECE4)));
}

.info--e7973 {
  background-color: var(--tkww-union-alerts-info-background-color, var(--tkww-union-color-status-info-100, #F4F9FF));
}

.success--c2dba {
  background-color: var(--tkww-union-alerts-success-background-color, var(--tkww-union-color-background-success, var(--tkww-union-color-status-success-100, #E2FFEE)));
}

.warning--57ee7 {
  background-color: var(--tkww-union-alerts-warning-background-color, var(--tkww-union-color-background-warning, var(--tkww-union-color-status-warning-100, #FFF3DD)));
}

.is-visible--e09ee {
  height: auto;
  opacity: 1;
  padding: 16px;
}

.is-not-visible--6bd1f {
  height: 0;
  opacity: 0;
  overflow: hidden;
  padding: 0;
}

.text-container--aa77c {
  display: flex;
  flex-direction: column;
}

.alert-cta-container--396bd {
  margin-top: 12px;
  --tkww-union-buttons-primary-alternative-border-color: var(--tkww-union-alerts-button-border-color);
  --tkww-union-buttons-primary-alternative-background-color: var(--tkww-union-alerts-button-background-color);
  --tkww-union-buttons-primary-alternative-color: var(--tkww-union-alerts-button-color);
  --tkww-union-buttons-primary-alternative-hover-border-color: var(--tkww-union-alerts-button-hover-border-color);
  --tkww-union-buttons-primary-alternative-hover-background-color: var(--tkww-union-alerts-button-hover-background-color);
  --tkww-union-buttons-primary-alternative-hover-color: var(--tkww-union-alerts-button-hover-color);
  --tkww-union-buttons-primary-alternative-disabled-border-color: var(--tkww-union-alerts-button-disabled-border-color);
  --tkww-union-buttons-primary-alternative-disabled-background-color: var(--tkww-union-alerts-button-disabled-background-color);
  --tkww-union-buttons-primary-alternative-disabled-color: var(--tkww-union-alerts-button-disabled-border);
  --tkww-union-buttons-primary-alternative-spinner-color: var(--tkww-union-alerts-button-spinner-color);
  --tkww-union-buttons-primary-alternative-pressed-color: var(--tkww-union-alerts-button-pressed-color);
  --tkww-union-buttons-primary-alternative-pressed-opacity: var(--tkww-union-alerts-button-pressed-opacity);
}

.close-button-container--dd36d {
  margin-left: auto;
}

.close-button--ece31 {
}

.close-button--ece31.close-button--ece31 {
  color: var(--tkww-union-alerts-close-icon-color, var(--tkww-union-color-icon-subtle, var(--tkww-union-color-neutral-500, #51545C)));
  margin: -10px;
}

.alert-headline--57021 {
  color: var(--tkww-union-alerts-headline-color, var(--tkww-union-color-text-default, var(--tkww-union-color-neutral-black, #000)));
  font-weight: bold;
}
